/* BUTTONS */

/* covers symantic button and div .button a */

/* buttons */
/* this might need to be edited for card specific only IMPORTANT !!! */

/* button */

button, .button {
  display: block;
  margin: .75rem 0;
  padding: .625rem 1rem .5rem;
  width: auto;
  height: 100%;
  font-family: 'Antenna Condensed Bold', 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.35;
  letter-spacing: .0125rem;
  word-spacing: .125rem;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
  border: none;
  border-radius: .33rem;
  background: $maroon;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  &:hover {
    color: $white;
    background-color: lighten($maroon, 10%);
  }
  :focus {
    outline: 3px solid $white;
    outline-offset: -3px;
    border-radius: .33rem !important;
    -moz-outline-border: .33rem;
  }
}

a button,
a button.button {
  height: auto !important;
}

button.container-100 {
  width: 100%;
}

button.container-50 {
  width: 50%;
}

button.container-auto {
  width: auto;
}

button.center {
  margin: .75rem auto;
}

.button-maroon button,
.button-patina button,
.bg-maroon button,
.bg-brick button,
.bg-gray button {
  color: $white !important;
  border: none !important;
}

.bg-maroon button,
.bg-brick button,
.button-gold button,
.bg-maroon .button-gold button {
  color: $black !important;
}

.button-maroon button { background-color: $maroon; }
.button-brick button { background-color: $brick; }
.button-patina button { background-color: $patina; }
.button-gold button { background-color: desaturate($gold,5%); color: $black !important; }

.bg-maroon button,
.bg-brick button { background-color: $gold;
  &:hover {
    color: $white;
    border: none;
    background: lighten($gold, 10%) !important;
  }
}

.bg-gold button,
.bg-gray button,
.bg-terracotta-extra-light button { background-color: $maroon; color: #000000 !important }

.button-maroon button:hover { background-color: lighten($maroon, 10%) !important; }
.bg-maroon .button-maroon button:hover { background-color: lighten($gold, 10%) !important; }
.button-patina button:hover { background-color: $patina-dark !important; }
.button-gold button:hover { background-color: lighten($gold,10%) !important; }

.button-open button {
  color: $maroon;
  border: solid .125rem $maroon;
  background: none;
  &:hover {
    color: $white;
    border: solid .125rem lighten($maroon, 10%);
    background: lighten($maroon, 10%);
  }
}

.card .content-grid-wrapper.bg-maroon .button-open button {
  color: lighten($gold, 10%) !important;
  border: solid .125rem lighten($gold, 10%) !important;
  background: none;
  &:hover {
    color: lighten($gold, 10%);
    border: solid .125rem lighten($gold, 10%);
    background: lighten($maroon, 10%);
  }
}

.flex-item button {
  width: 100%;
}

#panel-buttons button {
  height: 100%;
}

#panel-cards button {
  width: 100% !important;
}
